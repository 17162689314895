import { graphql, Link } from 'gatsby';
import { omit } from 'lodash';
import Loadable from 'react-loadable';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { normalizeShopCategories, sortShopifyData } from '../utils/shop';
import ShopProductCategory from '../components/Shop/ShopProductCategory';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import CurrencySelect from '../components/Header/currencyswitcher/currency-select';

const loader = () => <></>;
const LoadableShopTopCarousel = Loadable({
  loader: () => import('../components/Carousel/ShopTopSlider'),
  loading: loader,
});

const ShopPage = (props) => {
  const {
    data: {
      bgShop,
      allShopifyProducts: { edges: allProducts },
    },
  } = props;

  const [products, setProducts] = useState(allProducts);

  const normalizedShopCategories = normalizeShopCategories(products);
  const featuredCategory = normalizedShopCategories?.featured;

  const sortedProducts = sortShopifyData(omit(normalizedShopCategories, 'featured'));

  return (
    <BodyClassName className="body-light page-shop">
      <DefaultLayoutComponent title="Shop">
        <div className="site-main">
          <BackgroundImage className="page-banner bgImage" fluid={bgShop.childImageSharp.fluid} preserveStackingContext>
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-sm">
              <div className="pd-x-md pb-container">
                <h3 className="pb-title">Shop</h3>
              </div>
              <div className="carousel pd-x-sm">
                <div className="carousel-stage">
                  <LoadableShopTopCarousel featuredCategory={featuredCategory} />
                </div>
              </div>
            </div>
          </BackgroundImage>
          <div className="shop-container bg-dark">
            <div className="wrapper wrapper-xl pd-x-md">
              <section className="section bg-light pd-t-0 shop">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="breadcrumb pd-x-sm">
                    <div className="change-currency_shop">
                      <p>Change Currency</p>
                      <CurrencySelect mode="dark" />
                    </div>
                  </div>
                  <div className="product-container">
                    {!!sortedProducts &&
                      Object.keys(sortedProducts).map((productSlug, index) => {
                        const slicedProductList = sortedProducts[productSlug]
                          .sort((_next, _prev) => parseInt(_next.priority) - parseInt(_prev.priority))
                          .slice(0, 4);
                        return <ShopProductCategory key={index} category={slicedProductList} slug={productSlug} />;
                      })}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  query ShopPageQuery {
    bgShop: file(relativePath: { eq: "bg-shop.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allShopifyProducts: allShopifyProduct(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          tags
          title
          featuredImage {
            gatsbyImageData
          }
          metafields {
            key
            value
          }
          status
          createdAt
          priceRange: priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
        }
      }
    }
  }
`;

export default ShopPage;
